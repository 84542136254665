const isValidUrl = (url?: string) => {
  if (!url) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Combine two search param strings into one
 * In case of conflict on any key, the value of the second search param string will be used
 */
const combineSearchParams = (search1: string, search2?: string | null) => {
  const params1 = new URLSearchParams(search1);
  const params2 = search2 ? new URLSearchParams(search2) : new URLSearchParams();
  for (let [key, val] of params2.entries()) {
    params1.set(key, val);
  }
  
  const hasEntries = [...new Set(params1.keys())].length > 0;
  return hasEntries ? `?${params1.toString()}` : '';
};

/**
 * Returns the appropriate internal link by combining the search params
 * if and only if this is for a page script
 */
const getInternalLink = (linkURL: string, link: Gatsby.LinkFragment, sourceSearch: string, destinationSearch?: string | null) => {
  const isPageScript = link.type === 'page_script';
  const additionalSearch = isPageScript ? sourceSearch : '';
  const searchParams = combineSearchParams(additionalSearch, destinationSearch);

  return `${linkURL}${searchParams}`;
};

export { combineSearchParams, getInternalLink, isValidUrl };
