import { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link as ChakraLink } from "@chakra-ui/react";

import { LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { linkResolver } from "src/lib/linkResolver";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";

import patchSignupURL, { isSignupURL } from "./lib/patchSignupURL";
import patchAnchorURL from "./lib/patchAnchorURL";
import useIsBrowser from "src/hooks/useIsBrowser";
import { buildPageProperties } from "src/lib/event/properties";
import { logEvent } from "src/lib/analytics";
import { getInternalLink } from "./lib";

type LinkProps = ChakraLinkProps & { href?: string; to?: string };

/**
 * A simple link to which you can pass prop `to` (for internal links) or `href` (for external links)
 * and renders internal links with GatsbyLink or external links with `a`
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { href } = props;
  const isAnchorLink = href && href.startsWith("#");
  const isBrowser = useIsBrowser();

  /**
   * Add a `onClick` handler to anchor links to enable smooth scrolling only for anchors.
   * @note it requires a smooth-scroll polyfill to work on Safari.
   */
  if (isAnchorLink && isBrowser) {
    const targetId = href.slice(1);
    const target = document.getElementById(targetId);

    if (target) {
      return (
        <ChakraLink
          ref={ref}
          onClick={(e) => {
            e.preventDefault();

            const targetUpdated = document.getElementById(targetId);

            if (targetUpdated) {
              targetUpdated.scrollIntoView({ behavior: "smooth" });

              // Change the URL to match the section's link.
              history.pushState(null, "", `#${targetId}`);
            }
          }}
          {...props}
        >
          {props.children}
        </ChakraLink>
      );
    }
  }

  return (
    <ChakraLink
      as={props.to ? GatsbyLink : undefined}
      href={href}
      ref={ref}
      {...props}
    >
      {props.children}
    </ChakraLink>
  );
});

/**
 * A Component which accepts the Link fields from Prismic (see Gatsby.LinkFragment)
 * and returns the appropriate Link (see `Link`).
 */
const PrismicLink = forwardRef<
  HTMLAnchorElement,
  LinkProps & { link: Gatsby.LinkFragment, param?: string | null, eventName?: string, eventLabel?: string }
>(({ link, children, eventLabel, eventName, param, ...props }, ref) => {
  const location = useLocation();
  const { search } = location;
  const hasEvent = !!eventName;
  const visitorContext = useVisitorContext();

  /**
   * Whenever the React app hydrates, we want to force links to re-render by updating their key.
   * This will force the addition of query params tied to the visitorContext.
   */
  const renderingEnvKey = useIsBrowser() ? "browser" : "ssr";

  /**
   * An internal link is relative, in contrast to an external link which is an absolute URL.
   */
  const isInternal =
    link.link_type === "Document" || link.link_type === "Media";

  if (!isInternal)
    return (
      <Link
        key={renderingEnvKey}
        href={
          isSignupURL(link.url)
            ? patchSignupURL(visitorContext, link.url)
            : patchAnchorURL(link.url)
        }
        ref={ref}
        target={link.target}
        {...props}
      >
        {children}
      </Link>
    );

  const linkURL = link.url ?? linkResolver(link);

  /**
   * Signup url is a relative url that once patched becomes absolute.
   * From /inscription/ to https://www.app.shine.fr/register/
   */
  if (isSignupURL(linkURL))
    return (
      <Link
        key={renderingEnvKey}
        href={patchSignupURL(visitorContext, linkURL)}
        ref={ref}
        target={link.target}
        {...props}
      >
        {children}
      </Link>
    );

  const onClickInternal = () => {
    const event = {
      name: eventName || '',
      properties: {
        ...buildPageProperties(location),
        label: eventLabel || ''
      }
    }

    logEvent(event);
  };

  // Because it is an internal link, the event will have time to be tracked
  const onClickProp = hasEvent ? { onClick: onClickInternal } : {};
  const to = getInternalLink(linkURL, link, search, param);

  return (
    <Link
      to={to}
      ref={ref}
      target={link.target}
      {...props}
      {...onClickProp}
    >
      {children}
    </Link>
  );
});

export default PrismicLink;
