import { FC, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { buildPageProperties, getPageSubCat } from "src/lib/event/properties";

/**
 * @see https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
 */
import "focus-visible/dist/focus-visible";

/**
 * For the font-face :
 * @see https://github.com/gatsbyjs/gatsby/issues/19400#issuecomment-692024937
 */
import { GatsbyBrowser } from "gatsby";

import "./main.css";

import VisitorContext from "src/providers/VisitorContext";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import Cookies from "src/components/Cookies";
import { Helmet } from "react-helmet";
import { identify, page } from "src/lib/analytics";
import { hasChangedLocation } from "src/lib/event";
import { hasPersistentParam } from "src/lib/queryParams";

// Enable smooth scrolling for all browsers, mainly Safari.
smoothscroll.polyfill();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  <VisitorContext>
    <Helmet>
      {/* gatsby-plugin-sitemap already puts in <head> the main sitemap of the website. */}
      {/* But the Copilote project is hosted on the same domain, and we should reference here its own sitemap too. */}
      <link
        rel="sitemap"
        type="application/xml"
        href="/blog/sitemap/sitemap-index.xml"
      />
    </Helmet>

    {element}
  </VisitorContext>
);

const PageLayout: FC<{
  href: string;
  path: string;
  search: string;
}> = ({ href, path, search, children }) => {
  const { updateVisitorContext } = useVisitorContext();

  useEffect(() => {
    // Save marketing/partnership source for tracking purpose
    // Use the last one visited
    const isVisitingTapfiliateTrackedLandingPage =
      /\/lp\/offre|\/lp\/partenaire|\/lp\/ref/.test(path);

    const isVisitingAffilaeTrackedLandingPage = /(\?|&)ae=\d+/.test(href);

    if (
      isVisitingTapfiliateTrackedLandingPage ||
      isVisitingAffilaeTrackedLandingPage
    ) {
      const newUrl = new URL(window.location.href);

      if (!newUrl.searchParams.has("va")) {
        newUrl.searchParams.append("va", Date.now().toString());

        window.history.replaceState({}, "", newUrl.href);
      }

      // Note that this will not overwrite the affiliateSource/LandingHref
      // if the user reloads the page (or revisits again at a later date)
      // without the affiliate path or query param - e.g. navigating directly to
      // www.shine.fr.
      updateVisitorContext({
        // there no concept of "affiliateSource" when using Affilae
        affiliateSource: isVisitingTapfiliateTrackedLandingPage
          ? path
          : "AFFILAE",
        affiliateLandingHref: newUrl.href,
      });
    }

    // For acquisition purposes, as long as the query params include utm, we record the last match
    if (hasPersistentParam(search)) {
      updateVisitorContext({ search });
    }
  }, [href, path, search, updateVisitorContext]);

  return <Cookies>{children}</Cookies>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { location, path },
}) => (
  <PageLayout path={path} href={location.href} search={location.search}>
    {element}
  </PageLayout>
);

export const onClientEntry: GatsbyBrowser["onClientEntry"] = async () => {
  /**
   * Used for the Wistia SDK.
   */
  window.wistiaInitQueue = window.wistiaInitQueue || [];

  if (typeof IntersectionObserver === `undefined`) {
    await import("intersection-observer");
  }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  const shouldLogPageView = hasChangedLocation(prevLocation, location);

  if (shouldLogPageView) {
    const pageSubCat = getPageSubCat(location);
    const pageSubCatObj = pageSubCat ? { pageSubCat1: pageSubCat } : {};

    // TODO: LATER: This setup is useful for organic AB testing
    // const shouldIncludeExperimentVariant = process.env.GATSBY_EXPERIMENT_VARIANT_NAME !== 'all';
    // const experimentVariantObj = shouldIncludeExperimentVariant
    //   ? { experimentVariant: process.env.GATSBY_EXPERIMENT_VARIANT_NAME }
    //   : {};

    // This setup is useful for paid AB testing
    const { pathname } = location;
    const isA = pathname.startsWith("/hello");
    const isB = pathname.startsWith("/welcome");
    const shouldIncludeExperimentVariant = isA || isB;
    const experimentVariantObj = shouldIncludeExperimentVariant
      ? { experimentVariant: isA ? "paid-new-hp-a" : "paid-new-hp-b" }
      : {};

    const properties = {
      // This value is logged to analytics tools and can be edited on Netlify > Environment Variables
      ...experimentVariantObj,
      pageEnvironment: process.env.GATSBY_DEPLOY_ENV,
      pageSite: "marketing site",
      ...pageSubCatObj,
      ...buildPageProperties(location),
    };

    if (shouldIncludeExperimentVariant) {
      // https://segment.com/docs/connections/destinations/catalog/mixpanel/#people
      identify(experimentVariantObj);
    }

    page("", document.title, properties);
  }
};

export const disableCorePrefetching: GatsbyBrowser["disableCorePrefetching"] =
  () => true;
